<template>
  <b-modal
    ref="my-modal"
    id="modal-1"
    :title="modalTitle"
    no-close-on-esc
    no-close-on-backdrop
    hide-header-close
    hide-footer
    header-bg-variant="primary"
    size="lg">
    <participants-for-new-modal
        :is-add-new-user-sidebar-active.sync="isAddNewLicenceSidebarActive"
        :trainer_id="this.trainer.trainer_id"
        :activity_id="this.activity.activity_id"
        :capacity="capacity"
        @updateParticipantsList="updateParticipantsList($event)"/>
    <b-tabs>
      <b-tab active>
        <template #title>
          <feather-icon icon="CalendarIcon" />
          <span>{{$t('Planning')}}</span>
        </template>
        <b-card-text>
     <!-- Bloklanmış Zaman Seçimi -->
              <b-form-checkbox
                id="checkbox-1"
                v-model="block_time"
                name="checkbox-1"
                value="checked"
              >
                <p>{{$t('Mark as Blocked Time')}}</p>
              </b-form-checkbox>

          <!-- Trainer Seçim -->
          <b-form-group
            ><label for="title">{{ $t("Trainer") }}</label>
            <v-select
              v-model="trainer"
              :options="optionsTrainer"
              label="full_name"
              class="select-config"
              @blur="$v.trainer.trainer_id.$touch()"
            >
                <span slot="no-options">{{ $t('No Suitable Trainers Found') }}</span>
            </v-select>
              <small v-if="isSubmit && !$v.trainer.idCheck" class="form-text text-danger" >{{$t("Trainer Selection Required")}}</small>
          </b-form-group>

          <!-- Aktivite Seçim -->
          <b-form-group
          v-if="block_time === 'checked' ? false : true"
            ><label for="title">{{ $t("Activity") }}</label>
            <v-select
              :clearable="false"
              v-model="activity"
              :options="optionsActivity"
              label="activity_name"
              class="select-config"
              :disabled="block_time === 'checked' ? true : false">
              <span slot="no-options">{{ $t('No Suitable Activity Found') }}</span>
            </v-select>
          </b-form-group>

          <template v-if="block_time === 'checked' ? false : true">

           <b-row>
            <b-col>
              <b-button
                  class="mb-1"
                  block
                  variant="outline-success"
                  @click="isAddNewLicenceSidebarActive = true"
                  v-if="activity.activity_id != '00000000-0000-0000-0000-000000000000'"
              >
                {{ $t("Participants") }}
                <feather-icon size="14" icon="UsersIcon" />
              </b-button>
            </b-col>
           </b-row>
<!--          <b-row-->
<!--          class="mb-1"-->
<!--          v-if="activity.activity_id != '00000000-0000-0000-0000-000000000000'">-->
<!--             <b-col>-->
<!--             <b-button-->
<!--              variant="gradient-warning"-->
<!--              class="btn-icon"-->
<!--              v-b-modal.modal-list-parti-->
<!--              block-->
<!--             >-->
<!--                  <b-badge-->
<!--                  variant="warning"-->
<!--                  class="badge-glow">-->
<!--                    <span class="font-small-1">{{participantsList.length}} / {{capacity}}</span>-->
<!--                  </b-badge>-->
<!--               &lt;!&ndash;    Katılımcı Listesi    &ndash;&gt;-->
<!--               {{ $t('Participant List') }}-->

<!--             </b-button>-->
<!--            </b-col>-->
<!--            <b-col>-->
<!--             <b-button-->
<!--              variant="gradient-success"-->
<!--              class="btn-icon"-->
<!--              v-b-modal.modal-add-parti-->
<!--              block-->
<!--              no-close-on-esc-->
<!--              no-close-on-backdrop-->
<!--              v-if="participantsList.length < capacity"-->
<!--              @click="defaultParti"-->
<!--             >-->
<!--             <b-badge-->
<!--              variant="success"-->
<!--              class="badge-glow">-->
<!--                <feather-icon icon="UserPlusIcon" />-->
<!--             </b-badge>-->
<!--               &lt;!&ndash;    Katılımcı Ekle    &ndash;&gt;-->
<!--               {{ $t('Add Participant') }}-->
<!--             </b-button>-->
<!--            </b-col>-->
<!--          </b-row>-->

<!--          &lt;!&ndash; katılımcı listele modal &ndash;&gt;-->
<!--          <b-modal-->
<!--            id="modal-list-parti"-->
<!--            centered-->
<!--            :title="`${activity.activity_name} AKTİVİTESİ KATILIMCILARI`"-->
<!--            ok-only-->
<!--            ok-title="Accept"-->
<!--            header-bg-variant="warning"-->
<!--            no-close-on-esc-->
<!--            no-close-on-backdrop-->
<!--            hide-footer-->
<!--            size="lg"-->
<!--          >-->
<!--            <b-badge variant="light-primary" class="d-block p-2" v-if="participantsList <= 0">-->
<!--              {{ $t('There Are No Participants!') }}-->
<!--            </b-badge>-->

<!--      <b-card-->
<!--      v-else-->
<!--       no-body class="mb-0">-->
<!--      <div class="m-2">-->
<!--        &lt;!&ndash; Table Top &ndash;&gt;-->
<!--        <b-row>-->
<!--          &lt;!&ndash; Per Page &ndash;&gt;-->
<!--          <b-col-->
<!--            cols="12"-->
<!--            md="6"-->
<!--            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"-->
<!--          >-->
<!--            &lt;!&ndash; <label>Show</label> &ndash;&gt;-->
<!--            <v-select-->
<!--              v-model="perPage2"-->
<!--              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"-->
<!--              :options="perPageOptions2"-->
<!--              :clearable="false"-->
<!--              class="per-page-selector d-inline-block mx-50"-->
<!--            />-->
<!--            <label>{{ $t("Show") }}</label>-->
<!--          </b-col>-->

<!--          &lt;!&ndash; Search &ndash;&gt;-->
<!--          <b-col cols="12" md="6">-->
<!--            <div class="d-flex align-items-center justify-content-end">-->
<!--              <b-form-input-->
<!--                v-model="filter2"-->
<!--                class="d-inline-block mr-1"-->
<!--                :placeholder="$t('Search...')"-->
<!--              />-->
<!--            </div>-->
<!--          </b-col>-->
<!--        </b-row>-->
<!--      </div>-->
<!--            <b-row>-->
<!--                 <b-col>-->

<!--   <b-table-->
<!--        hover-->
<!--        ref="refUserListTable"-->
<!--        class="position-relative"-->
<!--        :per-page="perPage2"-->
<!--        :current-page="currentPage2"-->
<!--        :items="participantsList"-->
<!--        responsive-->
<!--        :fields="tableColumns"-->
<!--        primary-key="id"-->
<!--        :sort-by.sync="sortBy2"-->
<!--        :filter="filter2"-->
<!--        :filter-included-fields="filterOn2"-->
<!--        @filtered="onFiltered2"-->
<!--        show-empty-->
<!--        :empty-text="$t('No Matching Creates Found')"-->
<!--        :empty-filtered-text="$t('There are no records matching your request!')"-->
<!--        :sort-desc.sync="isSortDirDesc2"-->
<!--      >-->
<!--        <template #cell(actions)="data">-->
<!--            <b-button-->
<!--              class="mr-1"-->
<!--              variant="danger"-->
<!--              @click="deleteParticipant(data.item.id)"-->
<!--              >{{ $t("Delete Participant") }}</b-button-->
<!--            >-->
<!--        </template>-->
<!--      </b-table>-->
<!--                 </b-col>-->
<!--            </b-row>-->
<!--            <b-row class="my-1">-->
<!--              <b-col cols="6"-->
<!--               sm=""-->
<!--            class="-->
<!--              d-flex-->
<!--              align-items-left-->
<!--              justify-content-left justify-content-sm-start-->
<!--              ml-2-->
<!--              radius-->
<!--              0-->
<!--            ">-->
<!--            <small v-if="$i18n.locale === 'tr'" class="mt-1"-->
<!--            >içerisindeki {{ totalRows2 }} kayıttan {{ currentPage2 * 1 }}-{{-->
<!--              currentPage2 * perPage2-->
<!--            }}-->
<!--            arası ({{ currentPage2 }}.sayfa) gösteriliyor-->
<!--          </small>-->
<!--          <small v-if="$i18n.locale === 'en'" class="mt-1">showing {{currentPage2*1}}-{{currentPage2*perPage2}} (page {{currentPage2}}) of {{totalRows2}} records</small>-->
<!--              </b-col>-->
<!--                <b-col-->
<!--            cols="6"-->
<!--            sm=""-->
<!--            class="-->
<!--              d-flex-->
<!--              align-items-right-->
<!--              justify-content-right justify-content-sm-end-->
<!--              radius-->
<!--              0-->
<!--              mr-1-->
<!--            "-->
<!--          >-->
<!--            <b-pagination-->
<!--              v-model="currentPage2"-->
<!--              :total-rows="totalRows2"-->
<!--              :per-page="perPage2"-->
<!--              aling="right"-->
<!--              first-number-->
<!--              last-number-->
<!--              class="mb-0 mt-1 mt-sm-0 r-0"-->
<!--              prev-class="prev-item"-->
<!--              next-class="next-item"-->
<!--            >-->
<!--              <template #prev-text>-->
<!--                <feather-icon icon="ChevronLeftIcon" size="18" />-->
<!--              </template>-->
<!--              <template #next-text>-->
<!--                <feather-icon icon="ChevronRightIcon" size="18" />-->
<!--              </template>-->
<!--            </b-pagination>-->
<!--          </b-col>-->
<!--            </b-row>-->
<!--            </b-card>-->

<!--          </b-modal>-->

<!--          &lt;!&ndash; katılımcı ekle modal &ndash;&gt;-->
<!--          <b-modal-->
<!--            id="modal-add-parti"-->
<!--            ref="cancel-add-participants"-->
<!--            centered-->
<!--            :title="`${activity.activity_name}  ${this.$t('ADD PARTICIPANT TO YOUR ACTIVITY')}`"-->
<!--            ok-only-->
<!--            ok-title="Accept"-->
<!--            header-bg-variant="success"-->
<!--            no-close-on-esc-->
<!--            no-close-on-backdrop-->
<!--            hide-footer-->
<!--          >-->
<!--        &lt;!&ndash; katılımcı ekle &ndash;&gt;-->
<!--          &lt;!&ndash; Müşteri Seçim &ndash;&gt;-->
<!--          <b-form-group-->
<!--            ><label for="title">{{ $t("Customer") }}</label>-->
<!--            <v-select-->
<!--              :clearable="false"-->
<!--              v-model="customer"-->
<!--              :options="customerListFiltered"-->
<!--              label="full_name"-->
<!--              class="select-config"-->
<!--              :disabled="block_time === 'checked' ? true : false"-->
<!--            >-->
<!--              <span slot="no-options">{{ $t('No Suitable Customer Found') }}</span>-->
<!--            </v-select>-->
<!--          </b-form-group>-->

<!--           &lt;!&ndash; Ürün Seçim &ndash;&gt;-->
<!--           <b-form-group-->
<!--            ><label for="title">{{ $t("Product") }}</label>-->
<!--              <v-select-->
<!--              :clearable="false"-->
<!--              v-model="product"-->
<!--              :options="productListByCustomer"-->
<!--              label="extraTitle"-->
<!--              class="select-config"-->
<!--              >-->
<!--                <span slot="no-options">{{ $t('No Suitable Product Found') }}</span>-->
<!--              </v-select>-->
<!--          </b-form-group>-->

<!--              <hr>-->
<!--       <template>-->
<!--      <div class="row float-right">-->
<!--        <div class="col">-->
<!--          <b-button variant="warning" class="mr-1" @click="cancelAddParticipants">-->
<!--            {{ $t("Cancel") }}-->
<!--          </b-button>-->
<!--          <b-button variant="primary" @click="saveAddParticipants">-->
<!--            {{ $t("Save") }}-->
<!--          </b-button>-->
<!--        </div>-->
<!--      </div>-->
<!--    </template>-->
<!--          </b-modal>-->
    </template>

           <b-row>
      <b-col sm="6">
        <b-form-group>
          <label for="startTime"></label>{{ $t("Start Time") }}
          <v-select
            v-model="appointment.startTime"
            :options="startTimeScala"
            value="key"
            label="label"
            :reduce="item => item.key"
            class="select-config">
          </v-select>
        </b-form-group>
      </b-col>
      <b-col sm="6">
        <b-form-group>
          <label for="endTime"></label>{{ $t("End Time") }}
          <v-select
            v-model="appointment.endTime"
            :options="endTimeScala"
            :reduce="item => item.key" label="label"
            class="select-config">
          </v-select>
        </b-form-group>
      </b-col>
    </b-row>
    <template v-if="block_time === 'checked' ? false : true">
              <!-- Detay Collapse -->
          <b-button v-b-toggle.my-collapse block variant="outline-primary"
            >{{ $t("Details") }}
            <feather-icon size="14" icon="ArrowDownCircleIcon" />
          </b-button>
          <b-collapse id="my-collapse" class="pt-1">

            <!-- Randevu Başlık -->
            <b-form-group>
              <label for="title">{{ $t("Title") }}</label>
              <b-form-input
                id="title"
                v-model="appointment.title"
                label="title"
              />
            </b-form-group>

            <!-- Randevu Açıklama -->
            <b-form-group>
              <label for="definition">{{ $t("Description") }}</label>
                <b-form-textarea
                  id="defination"
                  v-model="appointment.definition"
                  placeholder=""
                  rows="3"
                  max-rows="6"
                ></b-form-textarea>
            </b-form-group>

            <b-form-group
            v-if="false">
              <b-row>
                  <b-col sm="12">
                    <label for="delay-minute">{{ $t("Cannot Join After How Many Minutes") }}</label>
                    <v-select
                      v-model="minute"
                      :options="delayMinute"
                      class="select-config">
                    </v-select>
                  </b-col>
              </b-row>
            </b-form-group>

            <b-form-group>
              <b-row>
                <b-col sm="6">
                  <!-- Randevu Başlangıç Tarihi -->
                  <b-form-group>
                    <label for="startDate"></label>{{ $t("Start Date") }}
                    <b-form-input
                      id="startDate"
                      type="date"
                      disabled
                      v-model="appointment.startDate"
                      label="startDate"
                    />
                  </b-form-group>
                </b-col>
                <b-col sm="6">
                  <!-- Randevu Bitiş Tarihi -->
                  <b-form-group>
                    <label for="endDate"></label>{{ $t("End Date") }}
                    <b-form-input
                      id="endDate"
                      type="date"
                      disabled
                      v-model="appointment.endDate"
                      label="endDate"
                    />
                  </b-form-group>
                </b-col>
              </b-row>
            </b-form-group>
          </b-collapse>
    </template>
    </b-card-text>
      </b-tab>
      <!-- Rezervasyon Sekmesi -->
      <b-tab v-if="block_time === 'checked' ? false : true">
        <template #title>
          <feather-icon icon="ToolIcon" />
          <span>{{$t('Reservation')}}</span>
        </template>
      <!-- Salon Seçim -->
          <b-form-group
          class="mt-1"
          v-if="block_time === 'checked' ? false : true"
            ><label for="title">{{ $t("Room") }}</label>
            <v-select
              v-model="salon"
              :options="optionsRoom"
              label="room_name"
              :disabled="block_time === 'checked' ? true : false"
              class="select-config"
            >
                <span slot="no-options">{{ $t('No Suitable Room Found') }}</span>
            </v-select>
          </b-form-group>

          <b-form-group>
            <label for="title">{{ $t("Capacity") }}</label>
            <b-form-input
              v-model="capacity"
              id="basicInput"
              :placeholder="$t('Capacity')"
              :disabled="block_time === 'checked' ? true : false || isPT"
            />
          </b-form-group>
      </b-tab>
      <hr>
       <template>
      <div class="row float-right">
        <div class="col">
          <b-button variant="warning" class="mr-1" @click="cancel">
            {{ $t("Cancel") }}
          </b-button>
          <b-button variant="primary" @click="save">
            {{ $t("Save") }}
          </b-button>
        </div>
      </div>
    </template>
    </b-tabs>

  </b-modal>
</template>

<script>
import ParticipantsForNewModal from '@/views/facility/calendar/ParticipantsForNewModal'
import { required, email } from "vuelidate/lib/validators";
import { ref } from "@vue/composition-api";
import models from "@/models";
import {
  BFormInput,
  BLink,
  BImg,
  BAvatar,
  BMediaAside,
  BFormCheckbox,
  BFormGroup,
  BFormCheckboxGroup,
  BRow,
  BCol,
  BButton,
  BCardText,
  BCollapse,
  VBToggle,
  BTabs,
  BTab,
  BPopover,
  VBPopover,
  BFormTextarea,
  BModal,
  VBModal,
  BAlert,
  BBadge,
  BTable,
  BCard,
  BPagination
} from "bootstrap-vue";
import { mapActions, mapGetters, mapState } from "vuex";
import vSelect from "vue-select";
import Ripple from "vue-ripple-directive";
import moment from 'moment';
import {startTimeScalaModel,endTimeScalaModel} from '@/models/timeScala'

export default {
  name: "ModalAppointment",
  components: {
    ParticipantsForNewModal,
    BFormInput,
    BFormCheckbox,
    BFormGroup,
    BFormCheckboxGroup,
    BRow,
    BCol,
    BButton,
    BCardText,
    vSelect,
    BMediaAside,
    BAvatar,
    BCollapse,
    VBToggle,
    BTabs,
    BTab,
    BPopover,
    VBPopover,
    BFormTextarea,
    BModal,
    BAlert,
    BBadge,
    BTable,
    BCard,
    BPagination
  },
  directives: {
    "b-toggle": VBToggle,
    "b-popover": VBPopover,
    'b-modal': VBModal,
    Ripple,
  },
  data() {
    return {
      modalTitle: "",
      isAddNewLicenceSidebarActive: false,
      show: false,
      disabled: false,

      block_time: "not-checked",
      salon: {
        room_id: "00000000-0000-0000-0000-000000000000",
        room_name: this.$t("Select"),
        is_active: true,
      },
      customerBranchId: "",
      activity: {
        activity_id: "00000000-0000-0000-0000-000000000000",
        activity_name: this.$t("Select"),
        activity_time: 60,
        activity_type_id: "00000000-0000-0000-0000-000000000000",
      },
      trainer: {
        trainer_id: "00000000-0000-0000-0000-000000000000",
        full_name: this.$t("Select"),
      },
      customer: {
        person_id: "00000000-0000-0000-0000-000000000000",
        full_name: this.$t("Select"),
      },
      product: {
        trainer_product_id: "00000000-0000-0000-0000-000000000000",
        title: this.$t('Select')
      },
      optionsRoom: [],
      optionsActivity: [],
      optionsTrainer: [],
      repeatCount: 1,
      selectedDays: [],
      capacity: 0,
      instructorConfirmBaseData: [],
      distributor_person_list: [],
      company_branch_persons_data: [],
      personData: [],
      personBaseData: [],
      productListByCustomer: [],
      appointment: {
          title: "",
          definition: "",
          startDate: "",
          endDate: "",
          startTime: "",
          endTime: "",
      },
      delayMinute: ['0','10','15','20','25','30'],
      isPT: false,
      isSubmit: false,
      minute: '0',
      startTimeScala: startTimeScalaModel,
      endTimeScala: endTimeScalaModel,
      participantsList: [],
      tableColumns: [
        // { key: "name", label: this.$t("Name"), sortable: true },
        // { key: "last_name", label: this.$t("Surname"), sortable: true },
        { key: "customer.full_name", label: this.$t("Katılımcı Adı Soyadı") },
        { key: "product.title", label: this.$t("Ürün Adı") },
        { key: "actions", label: this.$t("İşlem") },
      ],
      currentPage2: 1,
      totalRows2: 1,
      isSortDirDesc2: false,
      sortDesc2: false,
      sortBy2: "id",
      filter2: null,
      perPageOptions2: [10, 25, 50, 100],
      perPage2: 25,

      filterOn2: [],
      selectedActivityTime: null,
      customerList: [],
      customerListFiltered: []
    };
  },
  setup() {
    const appointment = ref();
    return {
      salon: 1,
      appointment,
    };
  },
  methods: {
    updateParticipantsList(newParticipantsList){
      this.participantsList = newParticipantsList;
    },
    //listede ilgili id'li katılımcı verisini diziden sil (kayıta yollama)
    deleteParticipant(id){
      let arr = []
      this.participantsList.map(el => {
        if(el.id !== id){
          arr.push(el)
        }
      })
      this.participantsList = arr
    },
    //katılımcı listesi arama aksiyonu
    onFiltered2(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows2 = filteredItems.length;
      this.currentPage2 = 1;
    },
    //müşteri ve ürünün seçileceği an defaulta çekilmesi
    defaultParti(){
      //iki dizinin eleman farkı için -> differenceArrayFilter
      const differenceArrayFilter = (firstArray, secondArray) => {
        return firstArray.filter(firstArrayItem =>
            !secondArray.some(
                secondArrayItem => firstArrayItem.person_id === secondArrayItem.customer.person_id
            )
        );
      };
       this.customerListFiltered = differenceArrayFilter(this.customerList, this.participantsList)
       this.customer = {
        person_id: "00000000-0000-0000-0000-000000000000",
        full_name: this.$t("Select"),
      },
      this.product = {
        trainer_product_id: "00000000-0000-0000-0000-000000000000",
        title: this.$t('Select')
      }
    },
    //main modelın kapatılması
    cancel(){
      this.hideModal();
    },
    cancelAddParticipants(){
      this.$refs["cancel-add-participants"].hide();
    },
    // createTimeScala60() {
    //   const time = [];
    //   for (let i = 0; i < 24; i++) {
    //     if (i < 10) {
    //       time.push("0" + i + ":00");
    //     } else {
    //       time.push(i + ":00");
    //     }
    //   }
    //   this.timeScala = time;
    // },
    showPopover() {
      this.$root.$emit("bv::show::popover", "popover-button-1");
    },
    hidePopover() {
      this.$root.$emit("bv::hide::popover", "popover-button-2");
    },
    pushNotifications(status) {
      this.$emit("push-notification", status);
    },


    getRoomList() {
      this.$database.RoomService.get_room_company_branch(
        this.companyBranchId
      ).then((res) => {
        if (res.is_success === true || res.is_success === 1) {
          this.optionsRoom = res.result;
        }
      });
    },

    getActivityList() {
      this.$database.SchedulerService.get_activity_company_branch(
        this.companyBranchId
      ).then((res) => {
        if (res.is_success === true || res.is_success === 1) {
          this.optionsActivity = res.result;
        }
      });
    },

    // //seçilen aktivitiye ait ürünlere sahip müşterileri getir.
    // getCustomerList(activity_id, trainer_id){
    //   this.personBaseData = []
    //
    //      // console.log("Hocanın Id si" + this.trainer.trainer_id)
    //
    //     this.$database.AppointmentService.get_customer_by_activity_id_product(
    //       activity_id, trainer_id
    //
    //     ).then(res => {
    //       if(res.is_success === true || res.is_success === 1){
    //         res.result.map(el => {
    //           this.personBaseData.push({
    //               ...el,
    //               full_name: el.name + ' ' + el.last_name
    //             })
    //         })
    //         this.customerList = this.personBaseData
    //       }
    //     })
    // },

      //müşteriye ait ürünleri getirir, ürünün aktivite id si seçilen aktivite id ye eşitse döner.
    getProductListByCustomer(){


      this.productListByCustomer = []
      this.$database.AppointmentService.get_customer_products_for_select_by_activity_id(
        this.customer.person_id,this.trainer.trainer_id,
        this.companyBranchId,
        this.activity.activity_id
      ).then(res => {
        console.log('ürün listesi', res.result);

        if(res.is_success === true || res.is_success === 1){
          res.result.map(el => {
            if(el.activity_id === this.activity.activity_id){
              var end = moment(el.expiry_date)
              var start = moment(new Date())
              var diff = end.diff(start, 'days')
              if(diff >= 0 || el.expiry_date == null){
                this.productListByCustomer.push({
                  ...el,
                  extraTitle:
                  el.expiry_date === null ? el.title + ' (Paketin Süre Erme Tarih Sınırı Yok)' : el.title + ' - (Paketin Sona Ermesine ' + diff + ' ' + this.$t('Gün Kaldı)')
                })
              }
            }
          })
        }
      })
    },
    getTrainerList(company_branch_id) {
      //servis olarak studio instructor list ile aynı
      this.$database.TrainerService.get_studio_trainers(company_branch_id
      ).then((res) => {
        if (res.is_success === true || res.is_success === 1) {
          res.result.map((el) => {
            //omaylı eğitmenleri getirmek
            if (el.is_confirm === true || el.is_confirm === 1) {
              this.instructorConfirmBaseData.push({
                ...el,
                full_name: el.name + " " + el.last_name,
              });
            }
          });
          let key = "person_id";

          //uniq değer dizisi oluşturmak
          let arrayUniqueByKey2 = [
            ...new Map(
              this.instructorConfirmBaseData.map((item) => [item[key], item])
            ).values(),
          ];
          arrayUniqueByKey2.map((el) => {
            this.optionsTrainer.push(el);
          });
        }
      });
    },

    showModal() {
      this.$refs["my-modal"].show();
      this.participantsList = []; //model ilk açıldığında katılımcı listesi boş gelmeli
      this.modalTitle = this.$t("Add Calendar Field") + ' (' + moment(this.appointment.startDate).format('DD.MM.YYYY') + ')';
      this.getTrainerList(this.companyBranchId);
      this.getRoomList();
    },
    hideModal() {

    
      this.$refs["my-modal"].hide();
      
        this.optionsTrainer= [];
    },
    toggleModal() {
      // We pass the ID of the button that we want to return focus to
      // when the modal has hidden
      this.$refs["my-modal"].toggle("#toggle-btn");
    },
    save() {
      this.isSubmit = true;
      if (this.$v.trainer.idCheck) { //this.$v.appointment.title.required
        Date.prototype.addDays = function (days) {
          var date = new Date(this.valueOf());
          date.setDate(date.getDate() + days);
          return date;
        };

        //------

        //title dinamik geliyor, eğer içerik girildiyse girilen içeriği al boşsa dinamik ata
        if(this.appointment.title === '' && this.block_time !== 'checked'){
          this.appointment.title = this.trainer.full_name + '-' + this.activity.activity_name
        } else if(this.block_time == 'checked'){
          this.appointment.title = 'KULLANIM DIŞI ALAN'
        } else {
          this.appointment.title = this.appointment.title
        }

        this.appointment.company_branch_id =
          this.companyBranchId;

        //trainer id yerine person_id yollayı sp içinde into atıyorum
        this.appointment.trainer_id =
          this.$store.getters["auth/userInfo"].person_id;

        //aktivite id
        this.appointment.activity_id = this.activity.activity_id;

        //salon id
        this.appointment.room_id = this.salon.room_id;

        //eğitmen id
        this.appointment.trainer_id = this.trainer.trainer_id;

        // //müşteri id
        // this.appointment.customer_id = this.customer.person_id

        // //ürün id
        // this.appointment.trainer_product_id = this.appointment.trainer_product_id !=
        // "00000000-0000-0000-0000-000000000000" ? this.product.trainer_product_id : "00000000-0000-0000-0000-000000000000";

        var participantArr = []
        this.participantsList.map(el => {
          participantArr.push({
            "person_id":el.person_id,
            "trainer_product_id":el.trainer_product_id,
            "customer_product_sales_id": el.customer_product_sales_id
          })
        })
        this.participantsList = participantArr
        this.appointment.participantsList = this.participantsList

        //rezervasyon -> kapasite
        this.appointment.capacity = this.capacity;

        //------

        // let days = [0,1,2,3,4,5,6];

        // for(var t = 0; t < 3; t++){
        //   days.splice(days.length-1,0,days.splice(0,1)[0])
        //   console.log(days);
        // }

        if (this.selectedDays.length > 0) {
          for (var i = 0; i < this.selectedDays.length; i++) {
            var startDay = this.appointment.start;
            var endDay = this.appointment.end;

            let dayDiff =
              this.selectedDays[i] - this.appointment.start.getDay();
            if (dayDiff < 0) dayDiff += 7;

            for (var z = 0; z < this.repeatCount; z++) {
              this.appointment.startDate = this.$functions.DateFormat(
                startDay.addDays(dayDiff),
                "yyyy-mm-dd"
              );

              this.appointment.endDate = this.$functions.DateFormat(
                endDay.addDays(dayDiff),
                "yyyy-mm-dd"
              );

              // this.$store.dispatch("calendarEditv2/addAppointment", {
              //   Appointment: this.appointment,
              //   Room: this.salon,
              // });

              startDay = startDay.addDays(7);
              endDay = endDay.addDays(7);
            }
          }
        } else {

          //eğer bitiş zamanı 00.00 ise diğer gün başlangıcı demektir. 2021-11-24 18:00 - 2021-11-25 00:00
          // if(this.appointment.endTime == '00:00' && this.appointment.startDate == this.appointment.endDate){
          //     // 2021-11-25 => 2021-11-26
          //     let endDate = new Date(this.appointment.endDate)
          //     endDate = this.$functions.DateFormat(new Date(endDate.setDate(endDate.getDate() + 1)),"yyyy-mm-dd")
          //     this.appointment.endDate = endDate
          // }

          //date strleri güncelledim (model değişimi sonrası)
          this.appointment.startStr = this.$functions.UpdateDateStrPlus3(this.appointment.startDate, this.appointment.startTime, null);
          this.appointment.endStr = this.$functions.UpdateDateStrPlus3(this.appointment.endDate, this.appointment.endTime, null);

          //gidecek tarihten 1 saniye düşürme
          // let newdatetry = new Date(this.appointment.endStr)
          // newdatetry = newdatetry.getTime() - 1000
          // this.appointment.endStr = new Date(newdatetry)
          this.appointment.endStr = new Date(this.appointment.endStr)
          let year = this.$functions.DateFormat(this.appointment.endStr).split('.').reverse().join('-')
          let hourMinuteSecond = this.appointment.endStr.toString().split(' ')[4]
          let returnEndDate = this.$functions.UpdateDateStrPlus3(year, hourMinuteSecond, null);
          this.appointment.endStr = returnEndDate

          this.$store
            .dispatch("calendarEditv2/addAppointment", {
              Appointment: this.appointment,
              Room: this.salon,
            })
            .then((res) => {
              if (res.status === true) {
                this.$functions.Messages.success(res.messages);
                this.pushNotifications("GUNCELLENDI");
              }
            });
          // console.log({
          //   Appointment: this.appointment,
          //   participantsList: JSON.stringify(this.appointment.participantsList),
          //   Room: this.salon,
          // });

        }
        this.repeatCount = 1;
        this.selectedDays = [];
        this.hideModal();
      }
    },
    saveAddParticipants(){
      // {"person_id":"84eba672-1559-4cd1-aafa-6acfa8ad90bb","name":"Defne","last_name":"UYAR","company_branch_id":"f4d4e592-3e44-459d-b1aa-571bdf76ae0c","full_name":"Defne UYAR"}
      // {"customer_product_sales_id":"7abfe289-0303-493f-87f1-e72d1ab4f0f5","product_price_id":"6995ef7d-c016-4466-9215-1a501bb1d11d","person_id":"84eba672-1559-4cd1-aafa-6acfa8ad90bb","quantity":1,"trainer_product_id":"9c6fa9b3-9d17-4e20-9f1b-151daef0865d","title":"ZUMBA PAKET","description":"zumba","trainer_id":"aa6793ec-b893-4159-9aa7-527d0b00b5e3","activity_id":"8e2e5e78-ee88-4bb7-99dc-b8a37087e047","activity_name":"ZUMBA","name":"Levin","last_name":"Tahmaz","price":1000,"session":10,"image":"https://cdn-icons-png.flaticon.com/512/5799/5799080.png"}
      if(this.customer.person_id !== '00000000-0000-0000-0000-000000000000' && this.product.trainer_product_id !== '00000000-0000-0000-0000-000000000000'){
        this.participantsList.push({
          id: Date.now(),
          customer: this.customer,
          product: this.product
        })
        this.$refs["cancel-add-participants"].hide();
        this.$functions.Messages.success('Katılımcı ekleme başarılı')
      }else {
        if(this.customer.person_id === '00000000-0000-0000-0000-000000000000' && this.product.trainer_product_id !== '00000000-0000-0000-0000-000000000000'){
          this.$functions.Messages.error('Müşteri Seçimi Yapmalısınız')
        }else if(this.product.trainer_product_id === '00000000-0000-0000-0000-000000000000'  && this.customer.person_id !== '00000000-0000-0000-0000-000000000000'){
          this.$functions.Messages.error('Ürün Seçimi Yapmalısınız')
        }else {
          this.$functions.Messages.error('Müşteri ve Ürün Seçimi Yapmalısınız')
        }
      }
    }
  },
  watch: {
    block_time(val) {
      if (val === "checked") {
        this.activity.activity_id = "00000000-0000-0000-0000-000000000000";
        this.activity.activity_name = this.$t("Blocked Area");
        this.salon.room_id = "00000000-0000-0000-0000-000000000000";
        this.salon.room_name = this.$t("Blocked Area");
        this.capacity = 0;
      } else {

        this.salon.room_id = "00000000-0000-0000-0000-000000000000";
        this.salon.room_name = this.$t("Select");

        this.activity.activity_id = "00000000-0000-0000-0000-000000000000";
        this.activity.activity_name = this.$t("Select");

        this.customer.person_id = "00000000-0000-0000-0000-000000000000"
        this.customer.full_name = this.$t("Select")
      }
    },

    //eğitmen değiştiği an aktivite listesi de değişebilmeli
    trainer(val){
      this.optionsActivity = []
      this.activity = {
        activity_id: "00000000-0000-0000-0000-000000000000",
            activity_name: this.$t("Select"),
            activity_time: 60,
            activity_type_id: "00000000-0000-0000-0000-000000000000",
      }
      if(val.trainer_id !== '00000000-0000-0000-0000-000000000000')
      {
          this.$database.AppointmentService.get_new_activity_list_by_trainer_id(
            val.trainer_id,
            this.companyBranchId
          ).then(res => {
            if (res.is_success === true || res.is_success === 1) {
              this.optionsActivity = res.result;
            }
          })
      }
    },

    activity(val) {
      /*
      this.appointment.startTime = null
      this.appointment.endTime = null
      if(val.activity_time === 45){
        this.timeScala = this.minutes45
      }else if(val.activity_time === 60){
        this.timeScala = this.minutes60
      }*/

       if (this.block_time !== "checked" && val.activity_id !== '00000000-0000-0000-0000-000000000000') {
        // this.getCustomerList(this.activity.activity_id,this.trainer.trainer_id)

        //eğer activity type id pt değilse customer'ı select haline çek
        this.customer = {
        person_id: "00000000-0000-0000-0000-000000000000",
        full_name: this.$t("Select"),
       },

        //eğer activite type id pt değilse product'ı select haline çek
        this.product = {
        trainer_product_id: "00000000-0000-0000-0000-000000000000",
        title: this.$t('Select')
        },
        this.capacity = 10;
        this.isPT = false
      }

      //this.participantsList = []

      this.selectedActivityTime = val.activity_time
    },
    customer(val){
      // console.log('csustomer calisti', val);
      if(val.person_id !== "00000000-0000-0000-0000-000000000000"){
        this.getProductListByCustomer()
      }
    },
    participantsList(val){
      this.totalRows2 = val.length
    },
    // randevu başlangıç saati değiştiğinde
    "appointment.startTime": function (val) {
      if (
        this.startTimeScala.map(function(e) { return e.key; }).indexOf(val) >=
        this.endTimeScala.map(function(e) { return e.key; }).indexOf(this.appointment.endTime) // başlangıç saati bitiş saatini geçtiğinde
      ) {
        this.appointment.endTime =
          this.endTimeScala[this.startTimeScala.map(function(e) { return e.key; }).indexOf(val) + 3].key; // bitiş saatini ileri al
      }
    },
    // // randevu bitiş saati değiştiğinde
    "appointment.endTime": function (val) {
      if (
        this.endTimeScala.map(function(e) { return e.key; }).indexOf(val) <=
        this.startTimeScala.map(function(e) { return e.key; }).indexOf(this.appointment.startTime) // bitiş saati başlangıç saatinden geri kaldığında
      ) {
        this.appointment.startTime =
          this.startTimeScala[ this.endTimeScala.map(function(e) { return e.key; }).indexOf(val) - 3].key; // başlangıç saatini geri al
      }
    },

  },
  validations: {
      trainer: {
          //yazdığım custom filter
          idCheck(val,vm) {
             //lütfen seçiniz kısmında gelen default id var ise false dön yani seçim yaptırmaya zorla
            return vm.trainer.trainer_id === '00000000-0000-0000-0000-000000000000' ? false : true;
          }
      },
  },
  created() {
    // this.createTimeScala60();
    this.companyBranchId = this.$store.getters["auth/userInfo"].company_branch_id;
   

   //this.getRoomList();
    //this.getActivityList();
  // this.getTrainerList();
  },
};
</script>

<style>
#modal-1___BV_modal_title_{
  color: #fff;
}
#modal-list-parti___BV_modal_title_{
  color: #fff !important;
}
#modal-add-parti___BV_modal_title_{
  color: #fff !important;
}
</style>

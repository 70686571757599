<template>
  <b-modal
    ref="my-modal"
    id="modal-1"
    :title="modalTitle"
    no-close-on-esc
    no-close-on-backdrop
    hide-footer
    hide-header-close
    header-bg-variant="warning"
    size="lg">
    <participants
      :is-add-new-user-sidebar-active.sync="isAddNewLicenceSidebarActive"
      :time_table_id="time_table_id"
      :trainer_id="trainer_id"/>

    <b-tabs>
      <b-tab active>
        <template #title>
          <feather-icon icon="CalendarIcon" />
          <span>{{ $t("Planning") }}</span>
        </template>
        <b-card-text>
          <!-- Bloklanmış Zaman Seçimi -->
          <b-form-checkbox
            id="checkbox-1"
            v-model="block_time"
            name="checkbox-1"
            value="checked"
            disabled>
            <p>{{ $t("Mark as Blocked Time") }}</p>
          </b-form-checkbox>

          <b-form-group
            ><label for="title">{{ $t("Trainer") }}</label>
            <v-select
              :value="trainer_fullname"
              :options="optionsTrainer"
              label="full_name"
              class="select-config"
              disabled>
            </v-select>
          </b-form-group>

          <b-form-group v-if="block_time === 'checked' ? false : true"
            ><label for="title">{{ $t("Activity") }}</label>
            <v-select
              :value="activity_name"
              :options="optionsActivity"
              label="activity_name"
              class="select-config"
              disabled
            >
            </v-select>
          </b-form-group>

          <template v-if="block_time === 'checked' ? false : true">
            <b-form-group
              v-if="
                activity.activity_type_id ===
                '768027a8-6eb5-42f4-9e6e-b15f544439d0'
              "
              ><label for="title">{{ $t("Customer") }}</label>

              <v-select
                v-model="customer"
                :options="personBaseData"
                label="full_name"
                class="select-config"
                disabled
              >
                <span slot="no-options">{{
                  $t("No Activities Found For This Trainer")
                }}</span>
              </v-select>
            </b-form-group>

            <b-form-group
              v-if="
                activity.activity_type_id ===
                '768027a8-6eb5-42f4-9e6e-b15f544439d0'"><label for="title">{{ $t("Product") }}</label>
              <v-select
                v-model="product"
                :options="productListByCustomer"
                label="title"
                class="select-config"
                disabled
              >
              </v-select>
            </b-form-group>
          </template>

          <b-row>
            <b-col sm="6">
              <b-form-group>
                <label for="startTime"></label>{{ $t("Start Time") }}
                <v-select
                  v-model="appointment.startTime"
                  :options="startTimeScala"
                  :reduce="item => item.key" label="label"
                  class="select-config">
                </v-select>
              </b-form-group>
            </b-col>
            <b-col sm="6">
              <b-form-group>
                <label for="endTime"></label>{{ $t("End Time") }}
                <v-select
                    v-model="appointment.endTime"
                    :options="endTimeScala"
                    :reduce="item => item.key" label="label"
                    class="select-config">
                </v-select>
              </b-form-group>
            </b-col>
          </b-row>

          <b-button
            block
            variant="outline-success"
            @click="isAddNewLicenceSidebarActive = true"
            v-if="block_time == 'checked' ? false : true">
            {{ $t("Participants") }}
            <feather-icon size="14" icon="UsersIcon" />
          </b-button>
          <template v-if="block_time === 'checked' ? false : true">
            <b-button v-b-toggle.my-collapse block variant="outline-primary"
              >{{ $t("Details") }}
              <feather-icon size="14" icon="ArrowDownCircleIcon" />
            </b-button>
            <b-collapse id="my-collapse" class="pt-1">
              <b-form-group
                ><label for="title">{{ $t("Title") }}</label>
                <b-form-input
                  id="title"
                  v-model="appointment.title"
                  label="title"
                  @blur="$v.appointment.title.$touch()"
                />
                <small
                  v-if="isSubmit && !$v.appointment.title.required"
                  class="form-text text-danger"
                  >{{ $t("This field is required") }}</small
                >
              </b-form-group>

              <b-form-group
                ><label for="definition">{{ $t("Description") }}</label>
                <b-form-textarea
                  id="defination"
                  v-model="appointment.definition"
                  placeholder=""
                  rows="3"
                  max-rows="6"
                ></b-form-textarea>
              </b-form-group>

              <b-form-group v-if="false">
                <b-row>
                  <b-col sm="12">
                    <label for="delay-minute">{{
                      $t("Cannot Join After How Many Minutes")
                    }}</label>
                    <v-select
                      v-model="minute"
                      :options="delayMinute"
                      class="select-config"
                    >
                    </v-select>
                  </b-col>
                </b-row>
              </b-form-group>

              <b-form-group>
                <b-row>
                  <b-col sm="6">
                    <b-form-group>
                      <label for="startDate"></label>{{ $t("Start Date") }}
                      <b-form-input
                        id="startDate"
                        type="date"
                        v-model="appointment.startDate"
                        label="startDate"
                        disabled
                      />
                    </b-form-group>
                  </b-col>
                  <b-col sm="6">
                    <b-form-group>
                      <label for="endDate"></label>{{ $t("End Date") }}
                      <b-form-input
                        id="endDate"
                        type="date"
                        v-model="appointment.endDate"
                        label="endDate"
                        disabled
                      />
                    </b-form-group>
                  </b-col>
                </b-row>
              </b-form-group>
            </b-collapse>
          </template>
        </b-card-text>
      </b-tab>
      <b-tab v-if="block_time === 'checked' ? false : true">
        <template #title>
          <feather-icon icon="ToolIcon" />
          <span>{{ $t("Reservation") }}</span>
        </template>
        <b-form-group v-if="block_time === 'checked' ? false : true">
          <label for="title">{{ $t("Room") }}</label>
          <v-select
            v-model="salon"
            :options="optionsRoom"
            label="room_name"
            :disabled="block_time === 'checked' ? true : false"
            class="select-config"
          >
            <span slot="no-options">{{ $t("No Suitable Room Found") }}</span>
          </v-select>
        </b-form-group>

        <b-form-group>
          <label for="capacity">{{ $t("Capacity") }}</label>
          <b-form-input
            v-model="capacity"
            id="basicInput"
            :placeholder="$t('Capacity')"
          />
        </b-form-group>
      </b-tab>
      <hr />
      <template>
        <div class="row mt-1">
          <div class="col">
            <b-button variant="danger" @click="deleteAppointment">
              {{ $t("Delete") }}
            </b-button>
          </div>
          <div class="float-right mr-1">
            <b-button variant="warning" class="mr-1" @click="cancel">
              {{ $t("Cancel") }}
            </b-button>
            <b-button variant="primary" @click="update">
              {{ $t("Update") }}
            </b-button>
          </div>
        </div>
      </template>
    </b-tabs>
  </b-modal>
</template>

<script>
import { required, email } from "vuelidate/lib/validators";
import moment from 'moment'
import { ref } from "@vue/composition-api";
import models from "@/models";
import Participants from "../Participants.vue";
import {
  BFormInput,
  BLink,
  BImg,
  BAvatar,
  BMediaAside,
  BFormCheckbox,
  BFormGroup,
  BFormCheckboxGroup,
  BRow,
  BCol,
  BButton,
  BCardText,
  BCollapse,
  VBToggle,
  BTabs,
  BTab,
  BPopover,
  VBPopover,
  BFormTextarea,
} from "bootstrap-vue";
import { mapActions, mapGetters, mapState } from "vuex";
import vSelect from "vue-select";
import Ripple from "vue-ripple-directive";
import { endTimeScalaModel, startTimeScalaModel } from '@/models/timeScala'

export default {
  name: "EditModalAppointment",
  components: {
    BFormInput,
    BFormCheckbox,
    BFormGroup,
    BFormCheckboxGroup,
    BRow,
    BCol,
    BButton,
    BCardText,
    vSelect,
    BMediaAside,
    BAvatar,
    BCollapse,
    VBToggle,
    BTabs,
    BTab,
    BPopover,
    VBPopover,
    Participants,
    BFormTextarea,
  },
  directives: {
    "b-toggle": VBToggle,
    "b-popover": VBPopover,
    Ripple,
  },
  data() {
    return {
      modalTitle: "",
      isAddNewLicenceSidebarActive: false,
      show: false,
      disabled: false,

      block_time: "not-checked",
      salon: {
        room_id: "00000000-0000-0000-0000-000000000000",
        room_name: this.$t("Select"),
        is_active: true,
      },
      activity: {
        activity_id: "00000000-0000-0000-0000-000000000000",
        activity_name: this.$t("Select"),
        activity_time: 60,
        activity_type_id: "00000000-0000-0000-0000-000000000000",
      },
      trainer: {
        trainer_id: "00000000-0000-0000-0000-000000000000",
        full_name: this.$t("Select"),
      },
      customer: {
        person_id: "00000000-0000-0000-0000-000000000000",
        full_name: this.$t("Select"),
      },
      product: {
        trainer_product_id: "00000000-0000-0000-0000-000000000000",
        title: this.$t("Select"),
      },
      optionsRoom: [],
      optionsActivity: [],
      optionsTrainer: [],
      repeatCount: 1,
      selectedDays: [],
      instructorConfirmBaseData: [],
      distributor_person_list: [],
      company_branch_persons_data: [],
      personData: [],
      personBaseData: [],
      productListByCustomer: [],
      appointment: {
        title: "",
        definition: "",
        startDate: "",
        endDate: "",
        startTime: "",
        endTime: "",
      },
      // timeScala: [],
      isPT: false,
      isSubmit: false,
      activity_id: null, //trainer calendar edit, handle event click ile gelecek düzenlenecek aktivite, aktivite idsi
      room_id: null, //trainer calendar edit, handle event click ile gelecek düzenlecek aktivite, salon idsi
      person_id: null,
      trainer_id: null,
      trainer_fullname:null,
      trainer_product_id: null,
      activity_name:null,
      capacity: null,
      activity_type_id: null,
      time_table_id: null,
      delayMinute: ["0", "10", "15", "20", "25", "30"],
      minute: "0",

      selectedActivityTime: null,
      startTimeScala: startTimeScalaModel,
      endTimeScala: endTimeScalaModel,
    };
  },
  setup() {
    const appointment = ref();
    return {
      appointment,
    };
  },
  methods: {
    // createTimeScala60() {
    //   const time = [];
    //   for (let i = 0; i < 24; i++) {
    //     if (i < 10) {
    //       time.push("0" + i + ":00");
    //     } else {
    //       time.push(i + ":00");
    //     }
    //   }
    //   this.timeScala = time;
    // },
    showPopover() {
      this.$root.$emit("bv::show::popover", "popover-button-1");
    },
    hidePopover() {
      this.$root.$emit("bv::hide::popover", "popover-button-2");
    },
    pushNotifications(status) {
      this.$emit("push-notification", status);
    },
    deleteAppointment() {
      this.$swal({
        title: this.$t("Are you sure?"),
        text: this.$t("Are you sure you want to delete the appointment"),
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: this.$t("Yes"),
        cancelButtonText: this.$t("No"),
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          this.$store
            .dispatch("calendarEditv2/deleteAppointment", {
              TimeTableId: this.time_table_id,
            })
            .then((res) => {
              if (res.status === true) {
                this.$functions.Messages.success(res.messages);
                this.pushNotifications("GUNCELLENDI");
              }
              this.hideModal();
            });
        }
      });
    },
    cancel() {
      this.capacity = null;
      this.activity_id = null;
      this.room_id = null;
      this.trainer_id = null;
      this.person_id = null;
      this.salon = {
        room_id: "00000000-0000-0000-0000-000000000000",
        room_name: this.$t("Select"),
        is_active: true,
      };
      this.activity = {
        activity_id: "00000000-0000-0000-0000-000000000000",
        activity_name: this.$t("Select"),
        activity_time: 60,
        activity_type_id: "00000000-0000-0000-0000-000000000000",
      };
      this.trainer = {
        trainer_id: "00000000-0000-0000-0000-000000000000",
        full_name: this.$t("Select"),
      };
      this.customer = {
        person_id: "00000000-0000-0000-0000-000000000000",
        full_name: this.$t("Select"),
      };
      this.product = {
        trainer_product_id: "00000000-0000-0000-0000-000000000000",
        title: this.$t("Select"),
      };
      this.hideModal();
    },
    getRoomList() {
      this.$database.RoomService.get_room_company_branch(
        this.$store.getters["auth/userInfo"].company_branch_id
      ).then((res) => {
        if (res.is_success === true || res.is_success === 1) {
          this.optionsRoom = res.result;
        }
      });
    },
    getActivityList() {
      this.$database.SchedulerService.get_activity_company_branch(
        this.$store.getters["auth/userInfo"].company_branch_id
      ).then((res) => {
        if (res.is_success === true || res.is_success === 1) {
          this.optionsActivity = res.result;
        }
      });
    },

    getTrainerList() {
      //servis olarak studio instructor list ile aynı
      this.$database.TrainerService.get_studio_trainers(
        this.$store.getters["auth/userInfo"].company_branch_id
      ).then((res) => {
        if (res.is_success === true || res.is_success === 1) {
          res.result.map((el) => {
            //omaylı eğitmenleri getirmek
            if (el.is_confirm === true || el.is_confirm === 1) {
              this.instructorConfirmBaseData.push({
                ...el,
                full_name: el.name + " " + el.last_name,
              });
            }
          });
          let key = "person_id";

          //uniq değer dizisi oluşturmak
          let arrayUniqueByKey2 = [
            ...new Map(
              this.instructorConfirmBaseData.map((item) => [item[key], item])
            ).values(),
          ];
          arrayUniqueByKey2.map((el) => {
            this.optionsTrainer.push(el);
          });
        }
      });
    },

    //seçilen aktivitiye ait ürünlere sahip müşterileri getir.
    getCustomerList() {
      this.personBaseData = [];
      this.$database.AppointmentService.get_customer_by_activity_id_product(
        this.activity.activity_id
      ).then((res) => {
        if (res.is_success === true || res.is_success === 1) {
          res.result.map((el) => {
            this.personBaseData.push({
              ...el,
              full_name: el.name + " " + el.last_name,
            });
          });
          this.personData = this.personBaseData;
          this.personBaseData.map((el) => {
            if (el.person_id === this.person_id) {
              this.customer = {
                person_id: el.person_id,
                full_name: el.name + " " + el.last_name,
              };
            }
          });
        }
      });
    },

    //müşteriye ait ürünleri getirir, ürünün aktivite id si seçilen aktivite id ye eşitse döner.
    getProductListByCustomer() {
      this.productListByCustomer = [];
      this.$database.AppointmentService.get_customer_products_for_select(
        this.customer.person_id,
        this.$store.getters["auth/userInfo"].company_branch_id
      ).then((res) => {
        if (res.is_success === true || res.is_success === 1) {
          res.result.map((el) => {
            if (el.activity_id === this.activity.activity_id) {
              this.productListByCustomer.push(el);
            }
          });
          this.productListByCustomer.map((el) => {
            if (el.trainer_product_id === this.trainer_product_id) {
              this.product = {
                trainer_product_id: el.trainer_product_id,
                title: el.title,
              };
            }
          });
        }
      });
    },

    showModal() {
      this.$refs["my-modal"].show();
      this.modalTitle = this.$t("Edit Calendar Field") + ' (' + moment(this.appointment.startDate).format('DD.MM.YYYY') + ')';
      this.getRoomList();
    },
    hideModal() {
      this.$refs["my-modal"].hide();
    },
    toggleModal() {
      // We pass the ID of the button that we want to return focus to
      // when the modal has hidden
      this.$refs["my-modal"].toggle("#toggle-btn");
    },
    update() {
      this.isSubmit = true;
      if (this.$v.appointment.title.required) {
        Date.prototype.addDays = function (days) {
          var date = new Date(this.valueOf());
          date.setDate(date.getDate() + days);
          return date;
        };
         
        //------
        this.appointment.company_branch_id =
          this.$store.getters["auth/userInfo"].company_branch_id;

        //trainer id yerine person_id yollayı sp içinde into atıyorum
        this.appointment.trainer_id =
          this.$store.getters["auth/userInfo"].person_id;

        this.appointment.activity_id = this.activity_id;
        this.appointment.room_id = this.salon.room_id;

        this.appointment.trainer_id = this.trainer_id;

        this.appointment.trainer_product_id =
          "00000000-0000-0000-0000-000000000000";

        this.appointment.capacity = this.capacity;

        this.appointment.time_table_id = this.time_table_id;

        //------

        if (this.selectedDays.length > 0) {
          for (var i = 0; i < this.selectedDays.length; i++) {
            var startDay = this.appointment.start;
            var endDay = this.appointment.end;

            let dayDiff =
              this.selectedDays[i] - this.appointment.start.getDay();
            if (dayDiff < 0) dayDiff += 7;

            for (var z = 0; z < this.repeatCount; z++) {
              this.appointment.startDate = this.$functions.DateFormat(
                startDay.addDays(dayDiff),
                "yyyy-mm-dd"
              );

              this.appointment.endDate = this.$functions.DateFormat(
                endDay.addDays(dayDiff),
                "yyyy-mm-dd"
              );

              this.$store.dispatch("calendarEditv2/addAppointment", {
                Appointment: this.appointment,
                Room: this.salon,
              });

              startDay = startDay.addDays(7);
              endDay = endDay.addDays(7);
            }
          }
        } else {
          this.appointment.startStr = this.$functions.UpdateDateStrPlus3(this.appointment.startDate, this.appointment.startTime, null);
          this.appointment.endStr = this.$functions.UpdateDateStrPlus3(this.appointment.endDate, this.appointment.endTime, null);
          //eğer bitiş zamanı 00.00 ise diğer gün başlangıcı demektir. 2021-11-24 18:00 - 2021-11-25 00:00
          if (
            this.appointment.endTime == "00:00" &&
            this.appointment.startDate == this.appointment.endDate
          ) {
            // 2021-11-25 => 2021-11-26
            let endDate = new Date(this.appointment.endDate);
            endDate = this.$functions.DateFormat(
              new Date(endDate.setDate(endDate.getDate() + 1)),
              "yyyy-mm-dd"
            );
            this.appointment.endDate = endDate;
          }

          //date strleri güncelledim (model değişimi sonrası)
          // this.appointment.startStr =
          //   this.appointment.startDate +
          //   "T" +
          //   this.appointment.startTime +
          //   ":00+03:00";
          // this.appointment.endStr =
          //   this.appointment.endDate +
          //   "T" +
          //   this.appointment.endTime +
          //   ":00+03:00";

          //gidecek tarihten 1 saniye düşürme
          // let newdatetry = new Date(this.appointment.endStr);
          // newdatetry = newdatetry.getTime() - 1000;
          // this.appointment.endStr = new Date(newdatetry);
          // console.log({
          //   Appointment: this.appointment,
          //   TimeTableId: this.time_table_id,
          // })
          this.$store
            .dispatch("calendarEditv2/updateAppointment", {
              Appointment: this.appointment,
              TimeTableId: this.time_table_id,
            })
            .then((res) => {
              if (res.status === true) {
                this.$functions.Messages.success(res.messages);
                this.pushNotifications("GUNCELLENDI");
              }
            });
        }
        this.repeatCount = 1;
        this.selectedDays = [];

        this.hideModal();
      }
    },
  },
  watch: {
    capacity(val) {
      if (val === 0) {
        this.block_time = "checked";
      } else {
        this.block_time = "not-checked";
      }
    },
    //trainer calendar edit'den gelen ref activity id değeri ile optionsları gezerim eşit olanı seçerim activity modele atarım
    activity_id(val) {
      this.optionsActivity.map((el) => {
        if (el.activity_id === val) {
          this.activity = el;
        }
      });
    },
    room_id(val) {
      this.optionsRoom.map((el) => {
        if (el.room_id === val) {
          this.salon = el;
        }
      });
    },
    person_id(val) {
      this.personBaseData.map((el) => {
        if (el.person_id === val) {
          this.customer = el;
        }
      });
    },
    block_time(val) {
      if (val === "checked") {
        this.activity.activity_id = "00000000-0000-0000-0000-000000000000";
        this.activity.activity_name = this.$t("Blocked Area");
        this.salon.room_id = "00000000-0000-0000-0000-000000000000";
        this.salon.room_name = this.$t("Blocked Area");
        this.capacity = 0;
      } else {
        this.getRoomList();
        this.$database.AppointmentService.get_new_activity_list_by_trainer_id(
          this.trainer_id,
          this.$store.getters["auth/userInfo"].company_branch_id
        ).then((res) => {
          if (res.is_success === true || res.is_success === 1) {
            this.optionsActivity = res.result;
          }
        });
      }
    },
    activity(val) {
      this.getCustomerList();
      this.selectedActivityTime = val.activity_time;
    },
    customer(val) {
      this.getProductListByCustomer();
    },
    salon(val) {
      this.getRoomList();
    },
    // randevu başlangıç saati değiştiğinde
    "appointment.startTime": function (val) {
      if (
          this.startTimeScala.map(function(e) { return e.key; }).indexOf(val) >=
          this.endTimeScala.map(function(e) { return e.key; }).indexOf(this.appointment.endTime) // başlangıç saati bitiş saatini geçtiğinde
      ) {
        this.appointment.endTime =
            this.endTimeScala[this.startTimeScala.map(function(e) { return e.key; }).indexOf(val) + 3].key; // bitiş saatini ileri al
      }
    },
    // // randevu bitiş saati değiştiğinde
    "appointment.endTime": function (val) {
      if (
          this.endTimeScala.map(function(e) { return e.key; }).indexOf(val) <=
          this.startTimeScala.map(function(e) { return e.key; }).indexOf(this.appointment.startTime) // bitiş saati başlangıç saatinden geri kaldığında
      ) {
        this.appointment.startTime =
            this.startTimeScala[ this.endTimeScala.map(function(e) { return e.key; }).indexOf(val) - 3].key; // başlangıç saatini geri al
      }
    },
  },
  validations: {
    appointment: {
      title: {
        required,
      },
    },
  },
  created() {
    
   // this.createTimeScala60();
   //  this.getRoomList();
   // this.getActivityList();
   // this.getTrainerList();
   // this.getCustomerList();
  },
};
</script>

<style scoped></style>

<template>
  <div class="app-calendar overflow-hidden border">
    <div class="row no-gutters">
      <!-- TAKVİM -->
      <div class="col position-relative">
        <div class="card shadow-none border-0 mb-0 rounded-0">
          <div class="card-body pb-0">
            <!-- FİLTRELEME -->
<!--            <div class="row filter-row">-->
<!--              <div class="col d-flex  align-items-center">-->
<!--                <b-media-aside class="toggle-button down-icon" v-b-toggle.scheduler-filter>-->
<!--                  <b-avatar size="36" variant="light-primary">-->
<!--                    <feather-icon size="18" icon="ArrowDownIcon"/>-->
<!--                  </b-avatar>-->
<!--                </b-media-aside>-->
<!--                <span class="filter-text">{{ $t('Filter') }}</span>-->
<!--              </div>-->
<!--            </div>-->
            <!-- FİLTRELEME ALANLARI -->
<!--            <b-collapse id="scheduler-filter">-->
<!--              <div class="row">-->
<!--                &lt;!&ndash; SALONLARI FİLTRELE &ndash;&gt;-->
<!--                <div class="col-md-4 mb-2">-->
<!--                  <b class="text-primary">{{ $t('Select To Filter Rooms') }}</b>-->
<!--                  <v-select-->
<!--                      v-model="salon"-->
<!--                      :options="options"-->
<!--                      label="room_name"-->
<!--                      class="select-config"-->
<!--                      :placeholder="$t('ALL ROOMS')"-->
<!--                      :clearable="false"-->
<!--                  >-->
<!--                  </v-select>-->
<!--                </div>-->
<!--                &lt;!&ndash; EĞİTMENLERİ FİLTRELE &ndash;&gt;-->
<!--                <div class="col-md-4 mb-2">-->
<!--                  <b class="text-primary">{{ $t('Select To Filter Trainers') }}</b>-->
<!--                  <v-select-->
<!--                      v-model="trainer"-->
<!--                      :options="optionsTrainer"-->
<!--                      label="full_name"-->
<!--                      class="select-config"-->
<!--                      :placeholder="$t('ALL TRAINERS')"-->
<!--                      :clearable="false"-->
<!--                  >-->
<!--                  </v-select>-->
<!--                </div>-->
<!--                &lt;!&ndash; AKTİVİTELERİ PLANLA &ndash;&gt;-->
<!--                <div class="col-md-4 mb-2">-->
<!--                  <b class="text-primary">{{ $t('Select To Filter Activities') }}</b>-->
<!--                  <v-select-->
<!--                      v-model="activity"-->
<!--                      :options="optionsActivity"-->
<!--                      label="activity_name"-->
<!--                      class="select-config"-->
<!--                      :placeholder="$t('ALL ACTIVITES')"-->
<!--                      :clearable="false"-->
<!--                  >-->
<!--                  </v-select>-->
<!--                </div>-->
<!--              </div>-->
<!--            </b-collapse>-->

            <!-- KAYIT BULUNAMAZ İSE -->
            <b-alert
                v-if="eventCount === 0"
                v-height-fade
                show
                dismissible
                fade
                class="text-center"
                variant="danger"
            >
              <div class="alert-body">
                <span> {{ $t('No appointment records found') }} </span>
              </div>
            </b-alert>

            <!-- TAKVİM -->
            <full-calendar
                ref="refCalendar"
                :options="calendarOptions"
                class="full-calendar"
            />
          </div>
        </div>
      </div>
    </div>
    <!-- TAKVİME RANDEVU EKLEMEK -->
    <NewModalAppointment ref="refModal" @push-notification="pushNotification"/>
    <!-- TAKVİMDEKİ RANDEVUYU DÜZENLEMEK -->
    <EditModalAppointment ref="refEditModal" @push-notification="pushNotification"/>
  </div>
</template>

<script>
import FullCalendar from '@fullcalendar/vue'
import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridPlugin from '@fullcalendar/timegrid'
import interactionPlugin from '@fullcalendar/interaction'
import NewModalAppointment from '@/views/facility/calendar/components/NewModalAppointment.vue'
import EditModalAppointment from '@/views/facility/calendar/components/EditModalAppointment.vue'
import vSelect from 'vue-select'
import { heightFade } from '@core/directives/animations'
import Ripple from 'vue-ripple-directive'

import {
  BFormSelect,
  BAvatar,
  BAlert,
  BCollapse,
  BMediaAside,
  VBToggle,
} from 'bootstrap-vue'

export default {
  name: 'calender_edit',
  components: {
    NewModalAppointment,
    EditModalAppointment,
    FullCalendar, // make the <FullCalendar> tag available
    BFormSelect,
    BAlert,
    vSelect,
    BCollapse,
    BMediaAside,
    BAvatar,
    VBToggle,
  },
  directives: {
    heightFade,
    Ripple,
    'b-toggle': VBToggle
  },
  data() {
    const language = this.$store.getters['root/language']
    return {
      salon: {
        room_id: '00000000-0000-0000-0000-000000000000',
        room_name: this.$t('ALL ROOMS'),
        is_active: true,
      },
      trainer: {
        trainer_id: '00000000-0000-0000-0000-000000000000',
        full_name: this.$t('ALL TRAINERS'),
      },
      activity: {
        activity_id: '00000000-0000-0000-0000-000000000000',
        activity_name: this.$t('ALL ACTIVITES'),
        activity_time: 60,
      },
      options: [],
      optionsTrainer: [],
      instructorConfirmBaseData: [],
      optionsActivity: [],
      thisFilteredTempData: [],

      //takvime ait konfigürasyonlar
      calendarOptions: {

        firstDay: 1, //takvim gününün hangi günde başlayacağını set eder; Sunday=0, Monday=1, Tuesday=2...
        stickyFooterScrollbar: true,
        contentHeight: 'auto', //takvimin görüş alanının yüksekliğini ayarlar, tarihler yukarıdan kaybolmamasını desteklesin diye kullanıyorum
        stickyHeaderDates: true, //görünüm alanının başlıkların sabitlenmesi için
        buttonText: {
          today: this.$t('Today'),
          month: this.$t('Month'),
          week: this.$t('Week'),
          day: this.$t('Day'),
        },
        allDaySlot: true, //sol taraf tüm gün boyu seçeneği aktif
        allDayText: this.$t('All-Day'), //sol taraf tüm gün boyu seçeneği yazısı
        html: true,
        slotMinTime: '00:00:00', //Determines the first time slot that will be displayed for each day.
        slotMaxTime: '24:00:00', //Determines the last time slot that will be displayed for each day. In line with the discussion about the Event object, it is important to stress that this should be specified as an exclusive end time.
        plugins: [
          dayGridPlugin,
          timeGridPlugin,
          interactionPlugin, // needed for dateClick
        ],
        headerToolbar: {
          left: 'prev,next today',
          center: 'title',
          right: 'dayGridMonth,timeGridWeek,timeGridDay',
        },
        initialView: 'timeGridWeek',
        initialEvents: [], // alternatively, use the `events` setting to fetch from a feed
        editable: true,
        selectable: true,
        selectMirror: false, //seçim yapıldığında arkaplanda nerenin seçildiğini blok şeklinde gösterir
        dayMaxEvents: true,
        select: this.handleDateSelect,
        selectAllow: this.handleDateSelectAllow,
        selectOverlap: function (event) {
          //bloklanmıs alanın ustunden tekrar secmeyi onler (ust uste gelmesi)
          return event.rendering === 'background'
        },
        dayHeaderFormat: {
          weekday: 'long',
          day: 'numeric',
          omitCommas: true
        }, //takvimin günleri için 21 Pazar gibi
        weekends: true, //cumartesi, pazar dahil edilsin (default true)
        eventClick: this.handleEventClick,
        eventsSet: this.handleEvents,
        eventAdd: this.handleAppointment,

        // event içeriğini manipule etmemizi sağlar
        // eventContent: function(arg) {
        //     return {
        //       html: arg.event.title + '<i>some html</i>'
        //     }
        //   },

        /* you can update a remote database when these fire:
                eventAdd:
                eventChange:
                eventRemove:
        */
        locale: language,
        timeFormat: 'H(:mm)',
        events: [], //tüm etkinlikleri tutacak dizi
        views: {
          dayGridMonth: {
            // name of view
            titleFormat: {
              year: 'numeric',
              month: '2-digit',
              day: '2-digit'
            },
            // other view-specific options here
          },
          dayGrid: {
            // options apply to dayGridMonth, dayGridWeek, and dayGridDay views
          },
          timeGrid: {
            // options apply to timeGridWeek and timeGridDay views
          },
          week: {
            // options apply to dayGridWeek and timeGridWeek views
          },
          day: {
            // options apply to dayGridDay and timeGridDay views
          },
        },
        eventResizableFromStart: true,
        dragScroll: true,
        nowIndicator: true, //şu an ki zamanı takvimde kırmızı çizgi ile işaretler
        slotDuration: '01:00:00', //zaman aralıkları 1'er saat olsun
        slotLabelFormat: {
          //sol taraftaki etkinlik saat aralıklarını dakika 2 digit yapar 12 => 12.30
          hour: 'numeric',
          minute: '2-digit',
        },
        snapDuration: '01:00:00',
      },
      currentEvents: [],
      baseCalendarData: [],
      eventCount: null,
      counterForWeek: null,
      //params data
      companyBranchId: "",
      personId: ""
    }
  },
  methods: {
    pushNotification(status) {
      if (status === 'GUNCELLENDI') {
        this.baseCalendarData = []
        this.calendarOptions.events = []
       this.getSchedulerList(this.companyBranchId, this.personId)
      }
    },
    handleAppointment(data) {
    },
    handleWeekendsToggle() {
      this.calendarOptions.weekends = !this.calendarOptions.weekends // update a property
    },

    //EKLEMEK İÇİN AKTİVİTEYE TIKLANDIĞINDA
    handleDateSelect(selectInfo) {
      
        console.log("TİTLE ALANINI TEMİZ AÇMAK İÇİN");



      //TİTLE ALANINI TEMİZ AÇMAK İÇİN
      this.$refs.refModal.appointment.title = ''
      //İSSUBMİT KONTROLÜNÜ TEMİZ AÇMAK İÇİN
      this.$refs.refModal.isSubmit = false
      //EKLENECEK RANDEVU KAPASİTE 0 GELSİN
      this.$refs.refModal.capacity = 0
      //İSPT KONTROLÜNÜ TEMİZ AÇMAK İÇİN
      this.$refs.refModal.isPT = false;
      //EKLENECEK RANDEVU SALON ID VARSAYILAN OLARAK BAŞLATMAK
      (this.$refs.refModal.salon = {
        room_id: '00000000-0000-0000-0000-000000000000',
        room_name: this.$t('Select'),
        is_active: true,
      }),
          //EKLENECEK RANDEVU ACTIVITY ID VARSAYILAN OLARAK BAŞLATMAK
          (this.$refs.refModal.activity = {
            activity_id: '00000000-0000-0000-0000-000000000000',
            activity_name: this.$t('Select'),
            activity_time: 60,
          }),
          //EKLENECEK RANDEVU TRAINER ID VARSAYILAN OLARAK BAŞLATMAK
          (this.$refs.refModal.trainer = {
            trainer_id: '00000000-0000-0000-0000-000000000000',
            full_name: this.$t('Select'),
          }),
          //EKLENECEK RANDEVU BLOKLANMIŞ ZAMAN İŞARETSİZ BAŞLATMAK
          (this.$refs.refModal.block_time = 'not-checked')
      //EKLENECEK RANDEVU BAŞLAMA TARİHİ SEÇİLEN TARİH İLE BAŞLATMAK
      this.$refs.refModal.appointment.startDate = this.$functions.DateFormat(selectInfo.start, 'yyyy-mm-dd')
      //EKLENECEK RANDEVU BİTİŞ TARİHİ SEÇİLEN TARİH İLE BAŞLATMAK
      this.$refs.refModal.appointment.endDate = this.$functions.DateFormat(selectInfo.start, 'yyyy-mm-dd')
      //EKLENECEK RANDEVU BAŞLANGIÇ ZAMANI SEÇİLEN ZAMAN İLE BAŞLATMAK
      this.$refs.refModal.appointment.startTime = this.$functions.DateFormat(selectInfo.start, 'HH:MM:ss')
      //EKLENECEK RANDEVU BİTİŞ ZAMANI SEÇİLEN ZAMAN İLE BAŞLATMAK
      var dateManipulate = new Date(selectInfo.end);
      dateManipulate.setSeconds(dateManipulate.getSeconds() - 1);
      this.$refs.refModal.appointment.endTime = this.$functions.DateFormat(dateManipulate, 'HH:MM:ss')
      this.$refs.refModal.showModal()
    },

    //ÇOKLU TARİH SEÇİMLERİNİ YÖNETTİĞİM KISIM - SELECTALLOW PROPERTY İÇİN
    handleDateSelectAllow(selectInfo) {
      //END 00.00 DIŞINDAKİ NORMAL SEÇİMLER
      if (selectInfo.end.getHours() - selectInfo.start.getHours() > 1) {
        return false
      }//GÜN SONU İLE BİRLEŞEN SEÇİMLER , BAŞLANGICI 23 OLMAYAN, 1 SAAT AŞIMI YAŞAYAN
      else if (
          selectInfo.end.getHours() === 0 &&
          selectInfo.start.getHours() !== 23 &&
          selectInfo.start.getHours() - selectInfo.end.getHours() > 1
      ) {
        return false
      }//GÜN AŞIMI YAŞAYAN SEÇİMLER
          // else if (selectInfo.end.getDate() - selectInfo.start.getDate() >= 1)
          //   return false;

      //DİĞERLERİ
      else {
        return true
      }
    },

    //DÜZENLEMEK İÇİN AKTİVİTEYE TIKLANDIĞINDA
    handleEventClick(clickInfo) {

        console.log("Randevu güncelle tıklandı")
      // alert(JSON.stringify(clickInfo.event));  // alert(JSON.stringify(this.$refs.refEditModal.salon)) // alert(JSON.stringify(this.$refs.refEditModal.salon)) // this.$refs.refEditModal.appointment.salon.room_id = clickInfo.event.extendedProps.room_id // this.$refs.refEditModal.activity = clickInfo.event.extendedProps.activity_id // this.$refs.refEditModal.trainer = clickInfo.event.extendedProps.trainer_id // alert(clickInfo.event.extendedProps.room_id)

      //DÜZENLENECEK RANDEVU BAŞLIK
      this.$refs.refEditModal.appointment.title = clickInfo.event.title
      //DÜZENLENECEK RANDEVU AÇIKLAMA
      this.$refs.refEditModal.appointment.definition = clickInfo.event.extendedProps.definition
      //düzenlenecek aktivite ad
      this.$refs.refEditModal.activity_name = clickInfo.event.extendedProps.activity_name
      //DÜZENELENECEK RANDEVU AKTİVİTE ID
      this.$refs.refEditModal.activity_id = clickInfo.event.extendedProps.activity_id
      //DÜZENLENECEK RANDEVU SALON ID
      this.$refs.refEditModal.room_id = clickInfo.event.extendedProps.room_id
      //düzenlenecek eğitmen ad - soyad
      this.$refs.refEditModal.trainer_fullname = clickInfo.event.extendedProps.trainer_name + " " + clickInfo.event.extendedProps.trainer_lastname
      //DÜZENLENECEK RANDEVU EĞİTMEN ID
      this.$refs.refEditModal.trainer_id = clickInfo.event.extendedProps.trainer_id
      //DÜZENLENECEK RANDEVU EĞİTMEN ID
      this.$refs.refEditModal.person_id = clickInfo.event.extendedProps.person_id
      //DÜZENLENECEK RANDEVU AKTİVİTE TİP ID
      this.$refs.refEditModal.activity_type_id = clickInfo.event.extendedProps.activity_type_id
      //DÜZENLENECEK RANDEVU TİME TABLE ID
      this.$refs.refEditModal.time_table_id = clickInfo.event.extendedProps.time_table_id
      //DÜZENLENECEK RANDEVU TRAINER PRODUCT ID
      this.$refs.refEditModal.trainer_product_id = clickInfo.event.extendedProps.trainer_product_id
      //DÜZENLENECEK RANDEVU KAPASİTE
      this.$refs.refEditModal.capacity = clickInfo.event.extendedProps.capacity
      //DÜZENLENECEK RANDEVU BAŞLAMA TARİHİ SEÇİLEN TARİH
      this.$refs.refEditModal.appointment.startDate = this.$functions.DateFormat(clickInfo.event.start, 'yyyy-mm-dd')
      //DÜZENLENECEK RANDEVU BİTİŞ TARİHİ SEÇİLEN TARİH
      this.$refs.refEditModal.appointment.endDate = this.$functions.DateFormat(clickInfo.event.end, 'yyyy-mm-dd')
      //DÜZENLENECEK RANDEVU BAŞLAMA ZAMANI SEÇİLEN ZAMAN
      this.$refs.refEditModal.appointment.startTime = this.$functions.DateFormat(clickInfo.event.start, 'HH:MM:ss')
      //DÜZENLENECEK RANDEVU BİTİŞ ZAMANI SEÇİLEN ZAMAN
      // alert(JSON.stringify(clickInfo.event))
      var dateManipulate = new Date(clickInfo.event.end);
      dateManipulate.setSeconds(dateManipulate.getSeconds() - 1);
      this.$refs.refEditModal.appointment.endTime = this.$functions.DateFormat(dateManipulate, 'HH:MM:ss')
      this.$refs.refEditModal.showModal()
    },

    handleEvents(events) {
      console.log("Şimdi bu event çalıştı")


      this.currentEvents = events
    },
    getRoomList() {
      this.$database.RoomService.get_room_company_branch(
          this.$store.getters['auth/userInfo'].company_branch_id
      )
          .then((res) => {
            if (res.is_success === 1 || res.is_success === true) {
              this.options.push(this.salon)
              res.result.map(el => {
                this.options.push(el)
              })
            }
          })
    },
    getTrainerList() {
      //servis olarak studio instructor list ile aynı
      this.$database.TrainerService.get_studio_trainers(
          this.$store.getters['auth/userInfo'].company_branch_id
      )
          .then((res) => {
            if (res.is_success === true || res.is_success === 1) {
              res.result.map((el) => {
                //omaylı eğitmenleri getirmek
                if (el.is_confirm === true || el.is_confirm === 1) {
                  this.instructorConfirmBaseData.push(this.trainer)
                  this.instructorConfirmBaseData.push({
                    ...el,
                    full_name: el.name + ' ' + el.last_name,
                  })
                }
              })
              let key = 'person_id'

              //uniq değer dizisi oluşturmak
              let arrayUniqueByKey2 = [
                ...new Map(
                    this.instructorConfirmBaseData.map((item) => [item[key], item])
                ).values(),
              ]
              arrayUniqueByKey2.map((el) => {
                this.optionsTrainer.push(el)
              })
            }
          })
    },

    getActivityList() {
      this.$database.SchedulerService.get_activity_company_branch(
          this.$store.getters['auth/userInfo'].company_branch_id
      )
          .then((res) => {
            if (res.is_success === true || res.is_success === 1) {
              this.optionsActivity.push(this.activity)
              res.result.map(el => {
                this.optionsActivity.push(el)
              })
            }
          })
    },

    //trainer_id ve company_branch_id ile planlanmıs time table activitylerini alicam
    async getSchedulerList(company_branch_id,person_id) {
      //this.$database.SchedulerService.get_scheduler_list_by_trainer(
      await this.$database.SchedulerService.get_scheduler_list(company_branch_id,person_id)
          .then((res) => {
            if (res.is_success === true || res.is_success === 1) {
              res.result.map((el) => {
                //this.calendarOptions.events.push({
                // alert(el.end)
                var dateManipulateEnd = new Date(el.end);
                if (el.end.split('T')[1] === '23:59:59') {
                  dateManipulateEnd = dateManipulateEnd;
                }else {
                  dateManipulateEnd.setSeconds(dateManipulateEnd.getMilliseconds() + 60);
                }
          
                // alert(el.end)
                el.title = el.title == null ? 'KULLANIM DIŞI' : el.title //title kullanım dışı alan (çevrilecek)
                this.baseCalendarData.push({
                  time_table_id: el.time_table_id,
                  activity_color: el.activity_color,
                  title: el.title,
                  start: el.start,
                  end: dateManipulateEnd,
                  definition: el.definition,
                  trainer_product_id: el.trainer_product_id,
                  trainer_id: el.trainer_id,
                  activity_id: el.activity_id,
                  activity_type_id: el.activity_type_id,
                  activity_name: el.activity_name,
                  trainer_name: el.trainer_name,
                  trainer_lastname: el.trainer_lastname,
                  room_id: el.room_id,
                  person_id: el.person_id,
                  capacity: el.capacity,
                  editable: false, //sağa sola taşınma özelliği
                  //aktiviteye göre
                  backgroundColor: el.activity_color,
                  borderColor: el.activity_color,
                  textColor: el.activity_color,
                })
              })
              this.calendarOptions.events = this.baseCalendarData

              // this.baseCalendarData.map(el => {
              //   if(this.$functions.DateFormat(el.start))
              // })

              //haftanın ilk günü
              let d = new Date() //yeni tarih olustur
              let day = d.getDay() //günü al
              let diff = d.getDate() - day + (day == 0 ? -6 : 1) //gün farkını al
              let newdateWeekDay = new Date(d.setDate(diff))
              let counterForWeek = 0
              this.baseCalendarData.map((el) => {
                if (
                    this.$functions.DateFormat(el.start) >=
                    this.$functions.DateFormat(newdateWeekDay) &&
                    this.$functions.DateFormat(el.end) <=
                    this.$functions.DateFormat(
                        newdateWeekDay.getTime() + 7 * 24 * 60 * 60 * 1000
                    )
                ) {
                  counterForWeek++
                }
              })
              this.counterForWeek = counterForWeek
              this.eventCount = this.baseCalendarData.length
            }
          })
    },
  },
  //sayfa oluşturulduğunda salonları, eğtitmenleri,aktiviteleri ve etkinlikleri çek
  created() {
    window.semih = this.$refs.refCalendar

   // this.$store.getters['auth/userInfo'].company_branch_id,
   // this.$store.getters['auth/userInfo'].person_id
    this.companyBranchId= this.$store.getters['auth/userInfo'].company_branch_id;
    this.personId = this.$store.getters['auth/userInfo'].person_id
    //this.getRoomList()
    this.getSchedulerList(this.companyBranchId, this.personId)
    //this.getTrainerList()
    //this.getActivityList()
  },
  watch: {
    salon(val) {
      if (this.salon.room_id == '00000000-0000-0000-0000-000000000000'
          && this.trainer.trainer_id == '00000000-0000-0000-0000-000000000000'
          && this.activity.activity_id == '00000000-0000-0000-0000-000000000000') {
        this.calendarOptions.events = []
        this.calendarOptions.events = this.baseCalendarData
      } else if (val.room_id == '00000000-0000-0000-0000-000000000000') {
        this.calendarOptions.events = this.baseCalendarData
      } else {
        this.calendarOptions.events = []
        this.baseCalendarData.map(el => {
          if (el.room_id == val.room_id) {
            this.calendarOptions.events.push(el)
          }
        })
      }
    },
    trainer(val) {
      if (this.salon.room_id == '00000000-0000-0000-0000-000000000000'
          && this.trainer.trainer_id == '00000000-0000-0000-0000-000000000000'
          && this.activity.activity_id == '00000000-0000-0000-0000-000000000000') {
        this.calendarOptions.events = []
        this.calendarOptions.events = this.baseCalendarData
      } else if (val.trainer_id == '00000000-0000-0000-0000-000000000000') {
        this.calendarOptions.events = this.baseCalendarData
      } else {
        this.calendarOptions.events = []
        this.baseCalendarData.map(el => {
          if (el.trainer_id == val.trainer_id) {
            this.calendarOptions.events.push(el)
          }
        })
      }
    },
    activity(val) {
      if (this.salon.room_id == '00000000-0000-0000-0000-000000000000'
          && this.trainer.trainer_id == '00000000-0000-0000-0000-000000000000'
          && this.activity.activity_id == '00000000-0000-0000-0000-000000000000') {
        this.calendarOptions.events = []
        this.calendarOptions.events = this.baseCalendarData
      } else if (val.activity_id == '00000000-0000-0000-0000-000000000000') {
        this.calendarOptions.events = this.baseCalendarData
      } else {
        this.calendarOptions.events = []
        this.baseCalendarData.map(el => {
          if (el.activity_id == val.activity_id) {
            this.calendarOptions.events.push(el)
          }
        })
      }
    },
  },
}
</script>

<!-- stillendirme -->
<style lang="scss" src="@/styles/scss/trainerCalendar.scss"></style>
